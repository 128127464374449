<!-- 搜索 -->
<template>
    <div>
        <goodListTemp :title="searchKey"/>
    </div>
</template>

<script>
import goodListTemp from '@/components/goodListTemp'
import bus from '@/utils/eventBus'
export default {
    name: 'Search',
    components: { goodListTemp },

    data() {
        return {
            searchKey: this.$route.query.searchKey ? decodeURIComponent(this.$route.query.searchKey) : ''
        };
    },

    watch: {
        $route() {
            if (this.$route.query.searchKey) {
                this.searchKey = decodeURIComponent(this.$route.query.searchKey)
            }
        },
    },

    beforeDestroy() {
        bus.$emit('sendSearchKey')
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>

</style>